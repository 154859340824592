import { RootState } from '@cls/redux'
import { History } from 'history'
import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { connect, ConnectedProps } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

import { showMenu } from '../app/state/menu-redux'
import LoginForm from './components/LoginForm'

type LoginViewProps = {
  dispatch: (...args: Array<any>) => any,
  location: Location,
  history: History,
};

const mapStateToProps = (state: RootState) => {
  return {
    loginPending: state.user.pending,
    loggedIn:     state.user.loggedIn,
    loginError:   state.user.error,
  }
}

const connector = connect(mapStateToProps)
type TypesFromRedux = ConnectedProps<typeof connector>

export class LoginView extends PureComponent<LoginViewProps & TypesFromRedux> {

  componentDidMount() {
    // @ts-ignore We are setting this extra field in places
    const state = this.props.location.state
    // @ts-expect-error We know we might have set the field on window
    const to = window._loginRedirect || (state && state.from) || '/'
    if (to === '/') {
      this.props.dispatch(showMenu())
    }
  }

  render() {
    if (this.props.loggedIn) {
      // @ts-ignore We are setting this extra field in places
      const state = this.props.location.state
      // @ts-expect-error We know we might have set the field on window
      const to = window._loginRedirect || (state && state.from) || '/'
      // @ts-expect-error We know we want to set the field on window
      window._loginRedirect = null
      return <Redirect to={to !== '/login' ? to : '/'} push={true} />
    }

    return (
      <div className="app page-login">
        <Helmet title="Login" />
        <LoginForm {...this.props} />
      </div>
    )
  }

}

// @ts-expect-error Not sure what's going on here
export default withRouter(connector(LoginView))
