import { objectPath, Path } from '.'

/**
 * Deep setIn functions, modifies srcObj that's been passed in. Primary use case is immer drafts.
 */
export function setIn(obj: Record<string, any>, path: Path, val: unknown) {
  const keys = objectPath(path)

  if (!keys.length) {
    return val
  }

  const lastKey = keys.slice(-1)[0]
  const lastObj = keys.slice(0, -1).reduce((objArg, key) => (objArg[key] = objArg[key] || {}), obj)
  lastObj[lastKey] = val
  return obj
}
